import merge from "deepmerge"
import defaultTheme from "gatsby-theme-blog/src/gatsby-plugin-theme-ui/components"

const whiteGreen = `#E8F5E9`
const blueGray = `#282c35`
const teal = `#009688`

export default merge(defaultTheme, {
  colors: {
    // see https://theme-ui.com/theming/#colors
    // see predefined colors: https://www.w3schools.com/colors/colors_names.asp
    text: blueGray,
    primary: teal,
    heading: blueGray,
    background: whiteGreen,
    modes: {
      dark: {
        background: `black`,
        primary: `DarkOliveGreen`,
        secondary: `white`,
        highlight: blueGray,
      },
    },
  },
  fonts: {
    body: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: "Inter, sans-serif",
    monospace: "Menlo, monospace",
  },
  styles: {
    // see https://theme-ui.com/theme-spec/#styles
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
    },
    h1: {
      variant: "text.heading",
      fontSize: 5,
    },
    h2: {
      variant: "text.heading",
      fontSize: 4,
    },
    h3: {
      variant: "text.heading",
      fontSize: 3,
    },
    h4: {
      variant: "text.heading",
      fontSize: 2,
    },
    h5: {
      variant: "text.heading",
      fontSize: 1,
    },
    h6: {
      variant: "text.heading",
      fontSize: 0,
    },
    pre: {
      fontFamily: "monospace",
      overflowX: "auto",
      code: {
        color: "inherit",
      },
    },
    code: {
      fontFamily: "monospace",
      fontSize: "inherit",
    },
    table: {
      width: "100%",
      borderCollapse: "separate",
      borderSpacing: 0,
    },
    th: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    td: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    blockquote: {
      padding: "16px",
      backgroundColor: "highlight",
      borderRadius: "4px",
      // see https://theme-ui.com/theme-spec/#theme-scales
      borderLeft: (t) => `8px solid ${t.colors.primary}`,
      // 👇
      color: (t) => t.colors.accent,
    },
  },
})
